import React, {useEffect, useRef, useState} from "react";
import {DateTime} from "luxon";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";

const specialFontFamily = [
    '"Sacramento"', 'san-serif'
].join(',');

const basicFontFamily = '"Darker Grotesque"';

const sxCell = {
    borderBottom: 'none',
    textAlign: 'center',
}

const sxTop = { ...sxCell,
    paddingBottom: 0,
    fontWeight: 'bold',
    fontFamily: basicFontFamily,
    textShadow: 'none',
    fontSize: {xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem', xl: '3.5rem'}
}

const sxBottom = { ...sxCell,
    fontFamily: basicFontFamily,
    textShadow: 'none',
    paddingTop: 0,
    fontSize: {xs: '1rem', sm: '1rem', md: '1.5rem', lg: '2rem', xl: '2.5rem'}
}

const CountDown = () => {
    const [diff, setDiff] = useState(null);
    const toDay = DateTime.now();
    const [weddingDay, setWeddingDay] = useState(DateTime.local().plus(10000)) //DateTime.local(2022, 8, 11, 14, 0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDiff(weddingDay.diff(toDay, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']));
        }, 1000);

        return () => clearInterval(intervalId);
    })

    if (!diff) return null;
    console.log(diff);
    return <Table sx={{mb: 2}}>
        <TableBody>
            <TableRow>
                {/*<TableCell sx={sxTop}>{diff.days}</TableCell>*/}
                <TableCell sx={sxTop}>{diff.hours}</TableCell>
                <TableCell sx={sxTop}>{diff.minutes}</TableCell>
                <TableCell sx={sxTop}>{diff.seconds}</TableCell>
            </TableRow>
            <TableRow>
                {/*<TableCell sx={sxBottom}>Tage</TableCell>*/}
                <TableCell sx={sxBottom}>Stunden</TableCell>
                <TableCell sx={sxBottom}>Minuten</TableCell>
                <TableCell sx={sxBottom}>Sekunden</TableCell>
            </TableRow>
        </TableBody>
    </Table>
}

export default CountDown;
