import React from "react";
import Typography from "@mui/material/Typography";

const ParagraphHeader = ({title}) => {

    return <Typography
        variant={"h2"}
        sx={{marginBottom: 1, fontSize: '3rem'}}
    >
        {title}
    </Typography>

}

export default ParagraphHeader;
