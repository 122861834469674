import React from "react";
import {Box} from "@mui/material";

const PageImage = ({src, alt}) => {
    return <Box sx={{width: t => `calc(100%)`, position: 'relative', marginBottom: 4}}>
        <img style={{width: '100%'}} src={src} alt={alt}/>
    </Box>

}
export default PageImage;
