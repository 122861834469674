import {Fab, Icon, IconButton, InputBase} from "@mui/material";
import React from "react";


const ImageUploadButton = ({id, handleUploadClick, isFab, sx}) => {
    return <>
        <InputBase
            accept="image/*,video/mp4,video/x-m4v,video/*"
            sx={{display: "none"}}
            id={id}
            inputProps={{
                multiple: true
            }}
            type="file"
            onChange={handleUploadClick}
        />
        <label htmlFor={id}>
            {isFab ?
                <Fab
                    component={"span"}
                    sx={sx}
                    color="primary"
                    aria-label="upload"
                >
                    <Icon>upload</Icon>
                </Fab> :
                <IconButton component={"span"} aria-label="upload" color={"default"} sx={sx}>
                    <Icon>upload</Icon>
                </IconButton>
            }
        </label></>
}


export default ImageUploadButton;
