import React from "react";
import {Box, Link as HtmlLink} from "@mui/material";
import mainPic from '../../assets/images/eventlocation-schloss-hof-wien.jpeg';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import PagePaper from "../../components/PagePaper";
import PageImage from "../../components/PageImage";
import HeaderOneImage from "../../components/HeaderOneImage";
import HeaderTwoImage from "../../components/HeaderTwoImage";
import Paragraph from "../../components/Paragraph";
import HeaderThreeImage from "../../components/HeaderThreeImage";

const containerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
};

const center = {
    lat: 48.21630403870035,
    lng: 16.93358205623548
};

const parkplatz = {
    lat: 48.21630403870035,
    lng: 16.93358205623548
};

const eingang = {
    lat: 48.2154261247134,
    lng: 16.93569491151932
};

const MyLink = ({href, label}) => (<HtmlLink
    sx={{
        fontWeight: 'bold',
        color: t => t.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }}
    target={"_blank"}
    href={href}
    rel={"noreferrer"}
>{label}</HtmlLink>)

const Location = () => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s"
    })

    return <Block>
        <PagePaper withImage>
            <PageImage alt={"Schloss Hof"} src={mainPic}/>
            {/* ----- ABSATZ 1 ----- */}
            <Box sx={{px: 4}}>
                <HeaderOneImage title={"Schloss Hof,"}/>
                <HeaderTwoImage title={"bei Engelhartstetten"}/>
                <Paragraph>
                    Liebe Freunde und Familie,
                </Paragraph>
                <Paragraph>
                    wir freuen uns euch nun zum Ort des Geschehens, wo wir uns das JA-Wort geben werden, einladen zu können.
                    Gemeinsam mit euch wollen wir auf <b>Schloss Hof</b> unsere Liebe am <b style={{whiteSpace: 'nowrap'}}>11.08.2022</b> feiern.
                </Paragraph>
                <Paragraph>
                    Jeder Gast ist namentlich an der Kassa beim Eingang hinterlegt und erhält somit eine Eintrittskarte für diesen Tag.
                    Schloss Hof öffnet bereits ab 10 Uhr vormittags und kann ab diesem Zeitpunkt von unseren Gästen betreten werden.
                </Paragraph>
                <Paragraph>
                    Vor der Hochzeit kann also das gesamte weitläufige Areal, wie auch der Streichelzoo, die Gärten und das Schloss
                    besucht oder Führungen (nicht inklusive) gebucht werden.
                </Paragraph>
                <Paragraph>
                    Gerne könnt ihr schon jetzt
                    eine <MyLink href={"https://360perspektiven.com/projekte/schlosshof_update4/"} label={"360° Tour durch Schloss Hof"}/> machen.
                </Paragraph>

                {/* ----- TRAUUNG ----- */}
                <HeaderThreeImage title={"TRAUUNG"}/>
                <Paragraph inset>
                    Um <b>14 Uhr</b> werden wir uns im historischen Schlossgebäude in romantischer Atmosphäre im <b>Festsaal</b> unseren
                    Bund fürs Leben feiern.
                </Paragraph>
                <Paragraph inset>
                    Wir bitten euch jedoch spätestens eine halbe Stunde vor der Trauung vor Ort zu sein.
                </Paragraph>

                {/* ----- DRESSCODE ----- */}
                <HeaderThreeImage title={"DRESSCODE"}/>
                <Paragraph inset>
                    Um sommerlich festliche Kleidung in gedeckten Farben wird gebeten.
                </Paragraph>
                <Paragraph inset>
                    Wir empfehlen euch ggf. Sonnenhüte, Sonnenbrillen und Sonnencreme mitzunehmen, um den warmen Temperaturen
                    im August stand zu halten.
                </Paragraph>
                <Paragraph inset>
                    <strong>Bleistiftabsätze werden von uns aufgrund der Kiesböden auf Schloss Hof nicht empfohlen. Wer mag, kann auch flache Schuhe
                        noch zusätzlich einpacken.</strong>
                </Paragraph>

                {/* ----- ANREISE ----- */}
                <HeaderThreeImage title={"ANREISE & PARKPLATZ"}/>

                <Typography
                    variant={"p"}
                    component={"p"}
                    sx={{ml: 1, mb: 1, mt: -2, fontSize: '1rem', textAlign: 'left', fontStyle: "italic", fontFamily: '"Darker Grotesque"'}}
                >
                    Schloßhof 1, 2294
                </Typography>
                <Paragraph inset>
                    Schloss Hof ist mit dem Auto in rund 50-60 Minuten von Wien über die Flughafenautobahn A4 (Abfahrt Fischamend),
                    die B9 (Richtung Hainburg) und die B49 erreichbar.
                    Auf dem Waldparkplatz unmittelbar vor dem Schloss stehen viele Parkplätze kostenfrei zur Verfügung.
                </Paragraph>
                <Paragraph inset>
                    Die Anreise mit der Bahn und dem Bus sind ebenfalls möglich und können von der
                    Schloss-Hof-Website entnommen werden
                    : <MyLink href={"https://www.schlosshof.at/besucherinfo/anreise"} label={"Anreisemöglichkeiten Schloss Hof"}/>
                </Paragraph>
                <Paragraph inset>
                    Ihr sucht eine Übernachtungsmöglichkeit in der Nähe? Hier findet ihr einige
                    mögliche <MyLink href={"/hotels"} label={"Unterkünfte"}></MyLink>.
                </Paragraph>
            </Box>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={16}
                    options={{gestureHandling: 'cooperative'}}
                >
                    <Marker
                        position={parkplatz}
                        label={"Parkplatz"}
                    />
                    <Marker
                        position={eingang}
                        label={"Eingang"}
                    />
                </GoogleMap>
            ) : <></>}

        </PagePaper>
    </Block>
}

export default Location;
