import React from "react";
import Typography from "@mui/material/Typography";

const HeaderTwoNoImage = ({title}) => {
    return <Typography
        variant={"h2"}
        sx={{marginBottom: 1, fontSize: {xs: '2.25rem', sm: '2.7rem'}}}
    >
        {title}
    </Typography>

}

export default HeaderTwoNoImage;
