import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AuthProvider from "./components/AuthProvider";
import {SnackbarProvider} from 'notistack';
import Slide from '@mui/material/Slide';
import NavigationProvider from "./components/NavigationProvider";

const specialFontFamily = [
    '"Sacramento"', 'san-serif'
].join(',');

const basicFontFamily = '"Darker Grotesque"';

const theme = createTheme({
    typography: {
        fontFamily: basicFontFamily,
        textShadow: 'none',
        h1: {
            fontFamily: specialFontFamily,
            textShadow: '4px 4px 4px #aaa',
        },
        h2: {
            fontFamily: specialFontFamily,
            textShadow: '4px 4px 4px #aaa',
        },
        h3: {
            fontFamily: specialFontFamily,
            textShadow: '4px 4px 4px #aaa',
        },
        h4: {
            fontFamily: specialFontFamily,
            textShadow: '4px 4px 4px #aaa',
        },
        h5: {
            fontSize: '2rem',
            fontFamily: specialFontFamily,
            textShadow: '4px 4px 4px #aaa',
        },
        p: {
            fontFamily: basicFontFamily,
            textShadow: 'none',
            fontSize: '1.3rem'
        }
    },
    components: {
        MuiSnackbarContent: {
            styleOverrides: {

                root: {
                    fontFamily: '"Roboto Mono"',
                    textShadow: 'none'
                }
            }
        },
    },
    palette: {
        primary: {
            main: '#052',
        },
        secondary: {
            main: '#333',
        },
        neutral: {
            main: '#aaa',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} TransitionComponent={Slide}>
                <Router>
                    <AuthProvider>
                        <NavigationProvider>
                            <App/>
                        </NavigationProvider>
                    </AuthProvider>
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
