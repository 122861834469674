import React, {useEffect, useState} from "react";
import {Grid, Icon, List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import Typography from "@mui/material/Typography";
import {onValue} from "firebase/database";
import {GUEST_LIST_REF, mealText} from "../../common/constants";
import Block from "../../components/Block";
import PagePaper from "../../components/PagePaper";

const TAXI_CONTEXT = {
    "yes": "Ja, bitte. Ich / Wir möchte /n ein Taxi bestellen.",
    "no": "Nein, ich / wir brauche /n keines. Danke!",
    "empty": "Keine sinvolle abgabe"
}


const GuestStats = () => {
    const [data, setData] = useState([])


    useEffect(() => {
        onValue(GUEST_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            snap.forEach(guestSnap => {
                newList.push(guestSnap.val());
            })
            setData(newList);
        })
    }, [])

    const checkData = (d) => {
        console.log(123, d);
        return 'test';
    }

    return <Block>
        <PagePaper>
            <Typography variant={"h4"}>Auswertungen</Typography>
            <Typography variant={"bordy"}>
                <Grid container spacing={1} sx={{m: 2}}>
                    <Grid item xs={5}>Eingeladene Personen</Grid>
                    <Grid item xs={7}>{data.reduce((a, e) => a + (e.invitations ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Trauung</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.marriage ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Agaape</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.agape ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Essen</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.dinner ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Party</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.party ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>davon Vielleicht</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.maybe ? 1 : 0), 0) ?? 0), 0)}</Grid>

                </Grid>

                <Grid container spacing={1} sx={{m: 2}}>
                    <Grid item xs={5}>Eingeladen von Ramona</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Ramona").reduce((a, e) => a + (e.invitations ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Trauung</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Ramona")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.marriage ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Agaape</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Ramona")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.agape ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Essen</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Ramona")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.dinner ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Party</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Ramona")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.party ? 1 : 0), 0) ?? 0), 0)}</Grid>
                </Grid>

                <Grid container spacing={1} sx={{m: 2}}>
                    <Grid item xs={5}>Eingeladen von Florian</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Florian").reduce((a, e) => a + (e.invitations ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Trauung</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Florian")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.marriage ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Agaape</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Florian")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.agape ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Essen</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Florian")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.dinner ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Party</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal).filter(d => d.site === "Florian")
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.party ? 1 : 0), 0) ?? 0), 0)}</Grid>
                </Grid>

                <Grid container spacing={1} sx={{m: 2}}>
                    <Grid item xs={5}>Essen zugesagt</Grid>
                    <Grid item xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.dinner ? 1 : 0), 0) ?? 0), 0)}
                    </Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Essenbestellung abgegeben</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.soup !== "" || m.main !== "" || (m.soup === "" && m.main === "" && m.child) ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Keine Suppe</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.soup === "" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Suppe 1</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.soup === "soupOne" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Suppe 2</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.soup === "SoupTwo" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Kein Hauptgang</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.main === "" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Hauptgang 1</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.main === "mainOne" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Hauptgang 2</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.main === "mainTwo" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Hauptgang 3</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal)
                    .reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.main === "mainThree" ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Ist Kind / Baby</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal).reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.child ? 1 : 0), 0) ?? 0), 0)}</Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={4}>Brauchen Hochstuhl</Grid>
                    <Grid item
                          xs={7}>{data.filter(d => !d.refusal).reduce((a, e) => a + (e?.meal?.reduce((acc, m) => acc + (m.chair ? 1 : 0), 0) ?? 0), 0)}</Grid>

                </Grid>
            </Typography>

            <Typography variant={"h5"} sx={{mt: 4}}>Missing Meals Selection</Typography>
            <List>
                {data
                .filter(d => !d.refusal && (!d.meal || (d.promiseNames?.some(p => p.dinner) && d.meal?.some(m => m.soup === "" && m.main === "" && !m.child))))
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={`${e.name}`}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Has mabe selected with no dinner ?</Typography>
            <List>
                {data
                .filter(d => !d.refusal && d.meal && d.promiseNames?.some(p => !p.dinner))
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={`${e.name}`}/>
                    </ListItem>
                ))}
            </List>


            <Typography variant={"h5"} sx={{mt: 4}}>Kinder / Baby Liste</Typography>
            <List>
                {data
                .filter(d => !!d.meal && !d.refusal)
                .flatMap((guest) => guest.meal.map(data => ({...data, familyName: guest.name})))
                .filter(guest => guest?.child)
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={`${e.name} (${e.age})`}
                                      secondary={e.familyName}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Hochstul Liste</Typography>
            <List>
                {data
                .filter(d => !!d.meal && !d.refusal)
                .flatMap((guest) => guest.meal.map(data => ({...data, familyName: guest.name})))
                .filter(guest => guest?.chair)
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={`${e.name} (${e.age})`}
                                      secondary={e.familyName}/>
                    </ListItem>
                ))}
            </List>


            <Typography variant={"h5"} sx={{mt: 4}}>Kommen nicht zur Agape</Typography>
            <List>
                {data
                .filter(d => !!d.promiseNames && !d.refusal)
                .flatMap((guest) => guest.promiseNames.map(data => ({...data, familyName: guest.name})))
                .filter(guest => !guest?.agape)
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={e.fullName} secondary={e.familyName}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Kommen nicht zum Essen</Typography>
            <List>
                {data
                .filter(d => !!d.promiseNames && !d.refusal)
                .flatMap((guest) => guest.promiseNames.map(data => ({...data, familyName: guest.name})))
                .filter(guest => !guest?.dinner)
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={e.fullName} secondary={e.familyName}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Kommen nicht zur Party</Typography>
            <List>
                {data
                .filter(d => !!d.promiseNames && !d.refusal)
                .flatMap((guest) => guest.promiseNames.map(data => ({...data, familyName: guest.name})))
                .filter(guest => !guest?.party)
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={e.fullName} secondary={e.familyName}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Taxi Informationen</Typography>
            <List>
                {data
                .filter(d => !!d.promiseNames && !d.refusal)
                .filter(guest => (!!guest?.taxi && guest?.taxi.selected === "yes") || (!!guest?.taxi && guest?.taxi.selected !== "yes" && guest?.taxi.feedback !== ""))
                .map((e, i) => (
                    <ListItem dense key={i}>
                        <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}} primary={e.name} secondary={<span>
                            <span>
                                {TAXI_CONTEXT[e.taxi.selected]}
                            </span><br/>
                            <span>
                                {e.taxi.feedback}
                            </span>
                        </span>}/>
                    </ListItem>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Essen</Typography>
            <List>
                {data
                .filter(d => !!d.meal && !d.refusal)
                .map((guest, i) => (<>
                        <ListSubheader key={i}>{guest.name}</ListSubheader>
                        {guest.meal.map((m, i) => (
                            <ListItem dense key={i}>
                                <ListItemText
                                    sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}}
                                    primary={<span>{m.child && <Icon>child_care</Icon>} {m.name}</span>}
                                    secondary={<span style={{display:'block'}}>
                                        <span style={{display:'block'}}>{mealText[m.soup]}</span>
                                        <span style={{display:'block'}}>{mealText[m.main]}</span>
                                    </span>}
                                />
                            </ListItem>
                        ))}
                    </>
                ))}
            </List>

            <Typography variant={"h5"} sx={{mt: 4}}>Essen Kinder</Typography>
            <List>
                {data
                .filter(d => !!d.meal && !d.refusal && d.meal.some(m => m.child))
                .map((guest, i) => (<>
                        <ListSubheader key={i}>{guest.name}</ListSubheader>
                        {guest.meal.filter(m => m.child).map((m, i) => (
                            <ListItem dense key={i}>
                                <ListItemText
                                    sx={{'.MuiListItemText-primary': {fontSize: '0.9em'}}}
                                    primary={<span>{m.child && <Icon>child_care</Icon>} {m.name}</span>}
                                    secondary={<span style={{display:'block'}}>
                                        <span style={{display:'block'}}>{mealText[m.soup]}</span>
                                        <span style={{display:'block'}}>{mealText[m.main]}</span>
                                    </span>}
                                />
                            </ListItem>
                        ))}
                    </>
                ))}
            </List>

        </PagePaper>

    </Block>
}

export default GuestStats;
