import React from "react";
import Typography from "@mui/material/Typography";

const HeaderTwoImage = ({title}) => {
    return <Typography
        variant={"h3"}
        component={"h2"}
        sx={{
            display: {xs: 'block', md: 'inline-block'},
            marginLeft: t => ({
                xs: t.spacing(0),
                sm: t.spacing(2),
            }),
            marginBottom: 2,
            fontSize: {xs: '1.25rem', sm: '1.75rem'},
            textAlign: 'left',
            fontFamily: '"Darker Grotesque"'
        }}
    >{title}</Typography>

}

export default HeaderTwoImage;
