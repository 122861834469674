import React, {useRef} from "react";
import {Box, Link as HtmlLink} from "@mui/material";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";

const containerStyleLine = {
    width: '200px',
    height: '200px',
    position: 'relative',
    borderRadius: '100%',
};

const linkStyling = {
    display: 'block', whiteSpace: 'nowrap', fontWeight: 'bold', color: t => t.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
};
const headerStyling = {display: 'block', textAlign: 'left', fontSize: '1.5em', fontFamily: '"Darker Grotesque"', marginTop: 1};
const HotelBlock = ({header, subtitle, url, center, zoom, route}) => {
    const ref = useRef();
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s"
    })

    const handleClick = (e) => {
        console.log(ref.current);
        if(ref.current) ref.current.click();
    }
    return <Box sx={{marginTop: 4, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        {isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyleLine}
                center={center}
                zoom={zoom}
                options={{disableDefaultUI: true, gestureHandling: 'cooperative', mapTypeId: 'satellite'}}
            >
                <Marker onClick={handleClick} position={center} label={{text: header, fontWeight: 'bold', fontSize: '1em', color: 'white'}} />
            </GoogleMap>
        ) : <></>}
        <Typography variant={"h4"} component={"h2"} sx={headerStyling}><b>{header}</b></Typography>
        <Typography variant={"p"}>{subtitle}</Typography>
        <Typography variant={"p"}>
            <HtmlLink
                ref={ref}
                sx={linkStyling}
                target={"_blank"}
                href={url}
                rel={"noreferrer"}
            >
                Hotel-Webseite
            </HtmlLink>
        </Typography>
        {route && <Typography variant={"p"}>
            <HtmlLink
                sx={linkStyling}
                target={"_blank"}
                href={route}
                rel={"noreferrer"}
            >
                Google Routen Planer
            </HtmlLink>
        </Typography>}

    </Box>
}

export default HotelBlock;
