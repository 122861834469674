import React from "react";
import {Box, Button, Container, Paper} from "@mui/material";
import mainPic from '../../assets/images/photo2.png';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

const basicFontFamily = '"Darker Grotesque"';

const Foto = () => {

    return <Block>
        <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>

                {/* ----- ABSATZ 1 ----- */}
                <Box sx={{width: t => `100%`, position: 'relative', marginBottom: 4}}>
                    <img style={{width: '100%'}} src={mainPic} alt={"Foto Aperat"}/>
                </Box>
                <Box sx={{pt: 2, px: 4, mb: 4, pb: 4}}>
                    <Typography variant={"h2"} component={"h1"} sx={{fontSize: {xs: '2.5rem', md:'3rem'}, display: 'inline-block'}}>Fotos,</Typography>
                    <Typography variant={"h3"} component={"h2"}
                                sx={{
                                    display: {xs: 'block', md: 'inline-block'}, marginLeft: t => ({
                                        xs: t.spacing(0),
                                        sm: t.spacing(2),
                                    }), marginBottom: 2, fontSize: {xs: '1.25rem', sm: '1.75rem'}, textAlign: 'left', fontFamily: '"Darker Grotesque"'
                                }}>vom 11.8.2022</Typography>

                    <Typography variant={"h3"} component={"h3"}
                                sx={{
                                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                                    textShadow: 'none'
                                }}>CLICK & SHARE</Typography>
                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Wir freuen uns sehr, wenn auch ihr von unserem besonderen Tag schöne Fotos macht. Ihr könnt sie hier gerne hochladen, um sie mit all unseren Gästen zu teilen.
                    </Typography>
                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Auch die Fotos unseres Hochzeitsfotografen werden über unsere Webseite mit euch geteilt.
                    </Typography>

                    <Typography variant={"h3"} component={"h3"}
                                sx={{
                                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                                    textShadow: 'none'
                                }}>TRAUUNG</Typography>
                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Während der Trauung bitten wir euch jedoch keine Fotos zu machen und auf den Sitzplätzen zu bleiben, da wir einen tollen Fotografen haben, welcher für uns schöne Fotos schießen wird. Danach dürft ihr gerne wieder eure Handys oder Kameras zücken und Fotos knipsen.
                    </Typography>

                    <Typography variant={"h3"} component={"h3"}
                                sx={{
                                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                                    textShadow: 'none'
                                }}>INSTAGRAM & CO.</Typography>
                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Wenn ihr Fotos auf Instagram, Facebook oder anderen Social Media Kanälen veröffentlichen wollt, so bitten wir euch die Gesichter der Kinder auf diesen Fotos aus datenschutzrechtlichen Gründen unkenntlich zu machen.
                    </Typography>

                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Weiters würden wir uns freuen, wenn ihr uns in euren Stories oder Posts mit dem Hashtag #flonaforever♾ und/oder unserem Profil @fl.o.na auf Instagram markiert.
                    </Typography>

                    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                        Dankeschön!
                    </Typography>

                    <Box><Button component={Link} variant={"text"} color={"primary"} to={"/gallery"}>Zur Gallery</Button></Box>

                </Box>
            </Paper>
        </Container>
    </Block>
}

export default Foto;
