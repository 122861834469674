import React, {useEffect, useRef, useState} from "react";
import {
    Backdrop, Box, Button, Chip, Fab, Icon, ImageList, ImageListItem, InputAdornment, LinearProgress, List, ListItem, TextField,
    Typography
} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Block from "../../components/Block";
import PagePaper from "../../components/PagePaper";
import {getStorage, ref, uploadBytes, listAll, getMetadata, uploadString, getBlob, getDownloadURL} from "firebase/storage";
import {useAuth} from "../../components/AuthProvider";
import FirebaseImage from "../../components/FirebaseImage";
import {child, onValue, set} from "firebase/database";
import {GALLERY_REF} from "../../common/constants";
import loadingGif from "../../assets/images/giphy.gif";
import FirebaseImageMeta from "../../components/FirebaseImageMeta";
import FirebaseVideo from "../../components/FirebaseVideo";
import {useNavigation} from "../../components/NavigationProvider";
import ImageUploadButton from "../../components/ImageUploadButton";
import FirebaseVideoMeta from "../../components/FirebaseVideoMeta";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";

const storage = getStorage();

const getItemsFromRef = async (ref) => {
    let items = [];
    try {
        const res = await listAll(ref);
        if (res.items.length > 0) items = [...items, ...res.items];
        if (res.prefixes.length > 0) {
            for (ref of res.prefixes) {
                const newItems = await getItemsFromRef(ref);
                items = [...items, ...newItems];
            }
        }
    } catch (e) {
        console.error(e);
    }
    return items;
}

const getUploader = (path) => {
    return `#${path.split('/').filter(t => !t.includes('images'))[0]}`;
}

const filterForLabel = (str) => (item) => {
    return getUploader(item.fullPath).toLowerCase().includes(str.toLowerCase()) || item.customMetadata.tags.toLowerCase().includes(str.toLowerCase());
}

const filterForTags = tags => item => {
    if(tags.length === 0) return true;
    const inCategory = tags.some(t => item.customMetadata?.category?.toLowerCase() === t.toLowerCase() );
    const inName = tags.some(t => getUploader(item.fullPath).toLowerCase() === '#'+t.toLowerCase());
    const inTags = tags.some(t => item.customMetadata?.tags?.toLowerCase().split('#').indexOf(t.toLowerCase()) > -1);

    return inCategory || inName || inTags;
}

const Gallery = () => {
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedRef, setSelectedRef] = useState(null);
    const [imageRefList, setImageRefList] = useState([]);
    const [imageRefListTrauung, setImageRefListTrauung] = useState([]);
    const [imageRefListAgape, setImageRefListAgape] = useState([]);
    const [imageRefListEssen, setImageRefListEssen] = useState([]);
    const [imageRefListParty, setImageRefListParty] = useState([]);
    const [imageListLoading, setImageListLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [searchArray, setSearchArray] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [uploadCounter, setUploadCounter] = useState(0);
    const [displayImageCount, setDisplayImageCount] = useState(0);
    const [displayImageCountTrauung, setDisplayImageCountTrauung] = useState(0);
    const [displayImageCountAgape, setDisplayImageCountAgape] = useState(0);
    const [displayImageCountEssen, setDisplayImageCountEssen] = useState(0);
    const [displayImageCountParty, setDisplayImageCountParty] = useState(0);
    const {user} = useAuth();
    const testRef = useRef();
    const searchRef = useRef();
    const {hideNavigation, showNavigation} = useNavigation();

    useEffect(() => {
        testRef.current.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (selectedRef) {
            hideNavigation();
        } else {
            showNavigation();
        }
    }, [selectedRef])

    function handleScroll() {
        if (testRef.current.clientHeight + testRef.current.scrollTop !== testRef.current.scrollHeight) return;
        if (displayImageCountTrauung <= imageRefListTrauung.length) {
            setDisplayImageCountTrauung(c => c + 25)
        } else if (displayImageCountAgape <= imageRefListAgape.length) {
            setDisplayImageCountAgape(c => c + 25);
        } else if (displayImageCountEssen <= imageRefListEssen.length) {
            setDisplayImageCountAgape(c => c + 25);
        } else if (displayImageCountParty <= imageRefListParty.length) {
            setDisplayImageCountAgape(c => c + 25);
        } else if (displayImageCount <= imageRefList.length) setDisplayImageCountAgape(c => c + 25);
    }

    useEffect(() => {
        onValue(GALLERY_REF, snap => {
            if (!snap.exists()) return;
            const lastUpdater = snap.val().update.split('#')[1]
            if (imageRefList.length === 0) {
                const imagesRef = ref(storage, 'images')

                getItemsFromRef(imagesRef).then(itemsList => {
                    const promiseList = itemsList.map(ref => getMetadata(ref));
                    Promise.all(promiseList).then(metaList => {

                        const listTrauung = metaList.filter(m => m.customMetadata?.category === "trauung");
                        const listAgape = metaList.filter(m => m.customMetadata?.category === "agape");
                        const listEssen = metaList.filter(m => m.customMetadata?.category === "essen");
                        const listParty = metaList.filter(m => m.customMetadata?.category === "party");
                        const listMissing = metaList.filter(m => !m.customMetadata?.category);

                        if (listTrauung.length > 0 && listTrauung.length > 10) {
                            setDisplayImageCountTrauung(50);
                        } else if (listAgape.length > 0 && listAgape.length > 10) {
                            setDisplayImageCountTrauung(listTrauung.length);
                            setDisplayImageCountAgape(50);
                        } else if (listEssen.length > 0 && listEssen.length > 10) {
                            setDisplayImageCountTrauung(listTrauung.length);
                            setDisplayImageCountAgape(listAgape.length);
                            setDisplayImageCountEssen(50);
                        } else if (listParty.length > 0 && listParty.length > 10) {
                            setDisplayImageCountTrauung(listTrauung.length);
                            setDisplayImageCountAgape(listAgape.length);
                            setDisplayImageCountEssen(listAgape.length);
                            setDisplayImageCountParty(50);
                        } else if (listMissing.length > 0) {
                            setDisplayImageCountTrauung(listTrauung.length);
                            setDisplayImageCountAgape(listAgape.length);
                            setDisplayImageCountEssen(listAgape.length);
                            setDisplayImageCountParty(listParty.length);
                            setDisplayImageCount(50);
                        }

                        setImageRefListTrauung(listTrauung);
                        setImageRefListAgape(listAgape);
                        setImageRefListEssen(listEssen);
                        setImageRefListParty(listParty);
                        setImageRefList(listMissing);

                        setImageListLoading(false);
                    })
                })
            }
            if (lastUpdater && imageRefList.length > 0) {
                const imagesRef = ref(storage, 'images/' + lastUpdater);
                getItemsFromRef(imagesRef).then(itemsList => {
                    const promiseList = itemsList.map(ref => getMetadata(ref));
                    Promise.all(promiseList).then(metaList => {
                        const clearedList = imageRefList.filter(element => !element.fullPath.includes(`/${lastUpdater}`))
                        setImageRefList([...clearedList, ...metaList]);
                        setImageListLoading(false);
                    })
                })
            }
            // Info: it is possible to be no last Updater in General not in this case
        })
    }, []);

    const handleUploadClick = category => event => {
        const files = event.target.files;
        let counter = 0;
        Array.from(files).forEach(file => {
            const refString = `images/${user.displayName}/${file.name}`;
            const storageRef = ref(storage, refString);
            const metadata = {
                customMetadata: {
                    'tags': '#flonaforever',
                    category
                }
            };
            counter++
            setUploadCounter(counter);
            if (file.type.includes("video")) {

                const _VIDEO = document.createElement("video");
                const _SOURCE = document.createElement("source");
                const _CANVAS = document.createElement("canvas");
                const _CTX = _CANVAS.getContext("2d");

                _SOURCE.type = file.type;
                _VIDEO.append(_SOURCE);
                _VIDEO.setAttribute('src', URL.createObjectURL(file));
                _VIDEO.setAttribute('crossorigin', "anonymous");

                // Load the video and show it
                _VIDEO.load();

                // Load metadata of the video to get video duration and dimensions
                _VIDEO.addEventListener('loadedmetadata', function() {
                    // Set canvas dimensions same as video dimensions
                    _CANVAS.width = _VIDEO.videoWidth;
                    _CANVAS.height = _VIDEO.videoHeight;
                });
                _VIDEO.currentTime = 0.001;
                _VIDEO.addEventListener('canplay', function() {
                    _CANVAS.style.display = 'inline';
                    _CTX.drawImage(_VIDEO, 0, 0, _VIDEO.videoWidth, _VIDEO.videoHeight);
                    const url = _CANVAS.toDataURL("image/png")

                    const name = file.name.split('.')[0];
                    const refStringPreview = `images/${user.displayName}/preview_${name}.png`;
                    const storageRefPreview = ref(storage, refStringPreview);
                    uploadString(storageRefPreview, url, 'data_url', metadata).then((snapshot) => {
                        console.log('Uploaded a data_url string!');
                    });
                });
            }
            uploadBytes(storageRef, file, metadata).then((snapshot) => {
                counter--
                setUploadCounter(counter);
                set(child(GALLERY_REF, 'update'), (new Date()).toISOString() + `#${user.displayName}`)
                .catch(e => console.error(e));
            }).catch(error => {
                console.error(error);
                counter--;
                setUploadCounter(counter)
            });
        })
    };

    const startDownload = async (e) => {
        e.stopPropagation();
        try {
            const url = await getDownloadURL(selectedRef);
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                //create a file from the returned blob
                const file = new File([blob], "image name", {type: blob.type});
                //grab the a tag
                const a1 = document.getElementById('tagID');
                //set the download attribute of the a tag to the name stored in the file
                a1.download = file.name;
                //generate a temp url to host the image for download
                a1.href = URL.createObjectURL(file);
            };
            xhr.open('GET', url);
            xhr.send();
        } catch (e) {
            console.error(e);
        }
    }

    const addSearchTag = e => {
        e.preventDefault();
        const data = new FormData(e.target)
        const json = Object.fromEntries(data);
        const str = json["searchTag"];
        searchArray.push(str);
        setSearchArray([...searchArray]);
        e.target.reset();
    }

    const removeSearchTag = tagName => e => {
        setSearchArray(searchArray.filter(e => e !== tagName));
    }

    return <Block>
        <PagePaper ref={testRef}>
            <HeaderOneNoImage title={"Foto Gallerie"}/>
            <HeaderTwoNoImage title={"alle Fotos die wir bekommen konnten"}/>
            <Paragraph>
                Sobald wir die Fotos vom Fotographen bekommen haben werden wir sie hier hochladen bis dahin würden wir uns freuen wenn all die
                Momentaufnahmen von euch auch hier zu uns finden könnten. Auch Videos mit dem Handy könnt ihr hier hochladen doch können wir nicht versprechen
                das jedes Videoformat auch dann zum abspielen funktioniert.
            </Paragraph>
            <Box component={"form"} onSubmit={addSearchTag}>
                <TextField
                    ref={searchRef}
                    variant={"outlined"}
                    fullWidth
                    id={"searchTag"}
                    name={"searchTag"}
                    placeholder={"#search for tag"}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Button
                                    aria-label="add search tag"
                                    type={"submit"}
                                >
                                    Add Search Tag
                                </Button>
                            </InputAdornment>
                    }}

                />
                <List component={"ul"} sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}>
                    {searchArray.map((chip, i) => (<ListItem sx={{width: 'auto', p: 1}} key={i}>
                        <Chip
                            label={chip}
                            onDelete={removeSearchTag(chip)}
                        />
                    </ListItem>))}
                </List>
            </Box>
            {imageListLoading && <LinearProgress/>}

            {/* ----------- Trauung ----------*/}
            {imageRefListTrauung.length > 0 &&
            <Typography variant={"h4"} sx={{mt: 2}}>
                Trauung
                <ImageUploadButton id={"upload-trauung"} handleUploadClick={handleUploadClick('trauung')}/>
            </Typography>
            }
            <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
                {imageRefListTrauung
                .filter(f => f.contentType.includes('image'))
                .filter(filterForTags(searchArray))
                .slice(0, displayImageCountTrauung)
                .map((item, i) => (
                    <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                                   sx={{'&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                        <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                    </ImageListItem>
                ))}
            </ImageList>

            {/* ----------- Agape ----------*/}
            {imageRefListAgape.length > 0 &&
            <Typography variant={"h4"} sx={{mt: 2}}>Agape <ImageUploadButton id={"upload-agape"} handleUploadClick={handleUploadClick('agape')}/></Typography>}
            <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
                {imageRefListAgape.filter(f => f.contentType.includes('image')).filter(filterForTags(searchArray)).slice(0, displayImageCountAgape)
                .map((item, i) => (
                    <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                                   sx={{'&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                        <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                    </ImageListItem>
                ))}
            </ImageList>
            {/* ----------- Essen ----------*/}
            {imageRefListEssen.length > 0 &&
            <Typography variant={"h4"} sx={{mt: 2}}>Abendessen <ImageUploadButton id={"upload-essen"} handleUploadClick={handleUploadClick('essen')}/></Typography>}
            <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
                {imageRefListEssen.filter(f => f.contentType.includes('image')).filter(filterForTags(searchArray)).slice(0, displayImageCountEssen)
                .map((item, i) => (
                    <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                                   sx={{'&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                        <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                    </ImageListItem>
                ))}
            </ImageList>
            {/* ----------- Party ----------*/}
            {imageRefListParty.length > 0 &&
            <Typography variant={"h4"} sx={{mt: 2}}>Party <ImageUploadButton id={"upload-party"} handleUploadClick={handleUploadClick('party')}/></Typography>}
            <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
                {imageRefListParty.filter(f => f.contentType.includes('image')).filter(filterForTags(searchArray)).slice(0, displayImageCountParty)
                .map((item, i) => (
                    <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                                   sx={{'&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                        <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                    </ImageListItem>
                ))}
            </ImageList>

            {/* ----------- Unkategorisiert ----------*/}
            {imageRefList.length > 0 && <Typography variant={"h4"} sx={{mt: 2}}>Unkategorisiert</Typography>}
            <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
                {imageRefList.filter(f => f.contentType.includes('image')).filter(filterForTags(searchArray)).slice(0, displayImageCount).map((item, i) => (
                    <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                                   sx={{'&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                        <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                    </ImageListItem>
                ))}
                {Array.from(Array(uploadCounter).keys()).map((e, i) => (
                    <ImageListItem key={i}>
                        <img src={loadingGif} alt={"loading"}/>
                    </ImageListItem>
                ))}
            </ImageList>
            <ImageUploadButton id={"upload-default"} handleUploadClick={handleUploadClick()} isFab sx={{
                position: 'fixed',
                bottom: t => t.spacing(2),
                right: t => t.spacing(2),
                zIndex: t => t.zIndex.drawer + 2
            }}/>

        </PagePaper>
        <Backdrop open={!!selectedRef} sx={{p: 1, zIndex: t => t.zIndex.drawer + 3}} onClick={() => setSelectedRef(null)}>
            <Fab
                sx={{
                    position: 'absolute',
                    top: t => t.spacing(2),
                    right: t => t.spacing(2),
                    zIndex: t => t.zIndex.drawer + 2
                }}
                color={"secondary"}
                aria-label="upload"
                onClick={() => setSelectedRef(null)}
            >
                <Icon>close</Icon>
            </Fab>
            <Fab
                component={"span"}
                sx={{
                    position: 'fixed',
                    bottom: t => t.spacing(6),
                    right: t => t.spacing(2),
                    zIndex: t => t.zIndex.drawer + 2
                }}
                color="primary"
                aria-label="download"
                onClick={startDownload}
            >
                <Icon>download</Icon>
            </Fab>
            {selectedRef && (selectedRef.name.includes('preview') ?
                <FirebaseVideo firebaseRef={selectedRef}
                               videoRefList={imageRefList.concat(imageRefListEssen, imageRefListParty, imageRefListTrauung, imageRefListAgape)
                               .filter(f => f.contentType.includes("video"))}/> :
                <FirebaseImage height firebaseRef={selectedRef} alt={selectedRef.name}/>)}
            {selectedRef && (selectedRef.name.includes('preview') ?
                <FirebaseVideoMeta
                    firebaseRef={selectedRef}
                    openDialog={() => setIsDialogOpen(true)}
                    handleClose={(del) => {
                        setIsDialogOpen(false);
                        if (!!del) setSelectedRef(null)
                    }}
                    editable={getUploader(selectedRef.fullPath) === `#${user.displayName}`}
                    videoRefList={imageRefList.concat(imageRefListEssen, imageRefListParty, imageRefListTrauung, imageRefListAgape)
                    .filter(f => f.contentType.includes("video"))}
                /> :
                <FirebaseImageMeta
                    firebaseRef={selectedRef}
                    openDialog={() => setIsDialogOpen(true)}
                    handleClose={(del) => {
                        setIsDialogOpen(false);
                        if (!!del) setSelectedRef(null)
                    }}
                    editable={getUploader(selectedRef.fullPath) === `#${user.displayName}`}
                />)}
        </Backdrop>
    </Block>
}

export default Gallery;
