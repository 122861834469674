import React from "react";
import {Box, Grid} from "@mui/material";
import Block from "../../components/Block";
import {GoogleMap, Marker, MarkerClusterer, useJsApiLoader} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import {HotelBlock} from "../../components";
import PagePaper from "../../components/PagePaper";

const containerStyle = {
    width: '100%',
    height: '350px',
};

const center = {
    lat: 48.16671226950312,
    lng: 16.940351466521452
};

const LIST = [{
    header: "Hotel Spitzerberg",
    subtitle: " 12 min. mit dem Auto entfernt",
    url: "http://www.spitzerberg.at/",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/Hotel+Spitzerberg,+Spitzerberg+1,+2405+Hundsheim,+%C3%96sterreich/@48.1592814,16.8442567,12z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476c5ff42798bebf:0xfa9afa3106506958!2m2!1d16.9306317!2d48.1041476!3e0?hl=de",
    center: {
        lat: 48.10425504642813,
        lng: 16.930610240213515
    },
    zoom: 18
}, {
    header: "Zum goldenen Anker",
    subtitle: "13 min. mit dem Auto entfernt",
    url: "http://www.goldeneranker.at/",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/Zum+goldenen+Anker,+Donaul%C3%A4nde,+Hainburg+an+der+Donau/@48.1768402,16.8802962,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476cf4e2f8b12a2b:0x712cec3cb942e32a!2m2!1d16.9394012!2d48.1486028!3e0?hl=de",
    center: {
        lat: 48.148601892056405,
        lng: 16.93940227125906
    },
    zoom: 18
}, {
    header: "FairSleep Motel Hainburg",
    subtitle: "15 min. mit dem Auto entfernt",
    url: "http://www.fairsleep-hotels.com/hotels-motels/oesterreich/niederoesterreich/motel-hainburg.html",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/FairSleep+Motel+Hainburg,+Pressburger+Reichsstra%C3%9Fe,+Hainburg+an+der+Donau/@48.1768402,16.887974,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476cf4df201f20f7:0xcce3cba5ad39fedc!2m2!1d16.9547492!2d48.1465511!3e0?hl=de",
    center: {
        lat: 48.14655199792411,
        lng: 16.954750812866994
    },
    zoom: 18
}, {
    header: "El Pirata",
    subtitle: "15 min. mit dem Auto entfernt",
    url: "https://rate-hotel-experience.com/pirates-austria",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/el+pirata,+Pressburgerreichstr,+Hainburg+an+der+Donau/@48.1768402,16.8881855,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476cf4df27376943:0x7243abdc32eb497f!2m2!1d16.9552009!2d48.146425!3e0?hl=de",
    center: {
        lat: 48.146459697052016,
        lng: 16.95508200790073
    },
    zoom: 18
}, {
    header: "Hotel Altes Kloster",
    subtitle: "15 min. mit dem Auto entfernt",
    url: "https://www.altes-kloster.com/",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/Hotel+Altes+Kloster+-+AK+Tagungshotel+Hainburg+GmbH,+Fabriksplatz,+Hainburg+an+der+Donau/@48.1768402,16.8820401,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476cf51d84f8070b:0x5e95dd66234158f5!2m2!1d16.9378463!2d48.1466394!3e0?hl=de",
    center: {
        lat: 48.146647867515966,
        lng: 16.93785548676546
    },
    zoom: 18
}, {
    header: "Hotel Fidi Wolfsthal",
    subtitle: "18 min. mit dem Auto entfernt",
    url: "https://www.fididashotel.at/",
    route: "https://www.google.com/maps/dir/Schloss+Hof,+Schlo%C3%9Fhof/FIDI+Hotel+-+Restaurant+Kurtschack+GmbH,+Sportplatzweg,+Wolfsthal/@48.1748463,16.910926,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x476cf3b8b8f1e5b1:0x510bfe453bd2eca1!2m2!1d16.937384!2d48.2138375!1m5!1m1!1s0x476c8adfc82431d9:0xe47debea003170e1!2m2!1d16.99865!2d48.13536!3e0?hl=de",
    center: {
        lat: 48.1353590322847,
        lng: 16.998650440960702
    },
    zoom: 18
}];

const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

const Hotel = () => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s"
    })
    return <Block>
        <PagePaper>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={11}
                        options={{disableDefaultUI: true, gestureHandling: 'cooperative', mapTypeId: 'satellite'}}
                    >
                        <Marker label={{text: "Schloss Hof", fontWeight: 'bold', fontSize: '1.4em', color: 'white'}} position={{
                            lat: 48.2154261247134,
                            lng: 16.93569491151932
                        }}/>
                        <MarkerClusterer options={options}>
                            {(clusterer) =>
                                LIST.map((element, i) => (
                                    <Marker label={{text: element.header, fontWeight: 'bold', fontSize: '1.2em', color: 'white'}} key={i}
                                            position={element.center} clusterer={clusterer}/>
                                ))
                            }
                        </MarkerClusterer>
                    </GoogleMap>
                ) : <></>}

                <Typography variant={"h2"} component={"h1"} sx={{fontSize: '3rem', display: 'block', marginTop: 4}}>Unterkünfte</Typography>
                <Typography variant={"h3"} component={"h2"}
                            sx={{display: 'block', marginBottom: 2, fontSize: '1.5rem', textAlign: 'left', fontFamily: '"Darker Grotesque"'}}>in der
                    Nähe</Typography>
            </Box>
            <Grid container spacing={2} justifyContent="center">
                {LIST.map((element, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <HotelBlock {...element}/>
                    </Grid>
                ))}
            </Grid>
        </PagePaper>
    </Block>
}

export default Hotel;
