import React from "react";
import {Avatar, Box, Typography, Icon} from "@mui/material";
import end from "../../../assets/icons/end-button-key.svg";

const wrapperStyling = {display: 'flex', minHeight: 60}
const emptyStylingRight = {width: { xs: '0%', sm: '50%' }, boxSizing: 'border-box', pl: '40px', position: "relative", pb: '40px'}
const fullStylingRight = {width:  { xs: '100%', sm: '50%' }, boxSizing: 'border-box', pl: '40px', position: "relative", pb: '40px'}
const emptyStylingLeft = {width: { xs: '0%', sm: '50%' }, boxSizing: 'border-box', pr: '40px', position: "relative", pb: '40px'}
const fullStylingLeft = {width: { xs: '100%', sm: '50%' }, boxSizing: 'border-box', pr: '40px', position: "relative", pb: '40px'}
const emptyStyling = {width: { xs: '0%', sm: '50%' }, boxSizing: 'border-box', px: {xs: '20px', sm: '40px'}, position: "relative", pb: '40px'}
const fullStyling = {width: { xs: '100%', sm: '50%' }, boxSizing: 'border-box', px: '40px', position: "relative", pb: '40px'}

const TimeBlock = ({isRight, time, title, text, icon, endBlock}) => {

    if(endBlock) return <Box sx={wrapperStyling}>
        <Box sx={emptyStyling}/>
        <Box sx={fullStyling}>
            <Avatar sx={{backgroundColor: '#333', position: 'absolute', top: '-25px', left: '-25px', zIndex: 2, height: 50, width: 50}}>
                <object width={25} height={25} data={end} />
            </Avatar>
        </Box>
    </Box>

    if (isRight) {
        return <Box sx={wrapperStyling}>
            <Box sx={{...emptyStylingRight, borderRight: 'solid 1px #333'}}/>
            <Box sx={{...fullStylingRight, borderLeft: 'solid 1px #333', textAlign: "left"}}>
                <Avatar sx={{backgroundColor: '#333', position: 'absolute', top: '-25px', left: '-25px',zIndex: 2, height: 50, width: 50}}>
                    <object width={25} height={25} data={icon} />
                </Avatar>
                <Typography variant={"h5"} sx={{wordBreak: 'break-all'}}>{title}</Typography>
                <Typography variant={"p"} sx={{fontWeight: "bold", textShadow: '2px 2px 2px #aaa'}}>{time}</Typography><br />
                <Typography variant={"p"}>{text}</Typography>
            </Box>
        </Box>
    }
    return <Box sx={wrapperStyling}>
        <Box sx={{...emptyStylingLeft, borderRight: 'solid 1px #333', textAlign: "right"}}>
            <Avatar sx={{backgroundColor: '#333', position: 'absolute', top: '-25px', right: '-25px', zIndex: 2, height: 50, width: 50}}>
                <object width={25} height={25} data={icon} />
            </Avatar>
            <Typography variant={"h5"} sx={{wordBreak: 'break-all'}}>{title}</Typography>
            <Typography variant={"p"} sx={{fontWeight: "bold", textShadow: '2px 2px 2px #aaa'}}>{time}</Typography><br />

            <Typography variant={"p"}>{text}</Typography>
        </Box>
        <Box sx={{...fullStylingLeft, borderLeft: 'solid 1px #333'}}/>
    </Box>


}

export default TimeBlock;
