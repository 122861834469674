import React, {createContext, useContext, useState} from "react";

const NavigationContext = createContext(null);
const NavigationProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const openNavigation = () => {
        setIsOpen(true);
    }

    const closeNavigation = () => {
        setIsOpen(false);
    }

    const hideNavigation = () => {
        setIsHidden(true);
    }

    const showNavigation = () => {
        setIsHidden(false);
    }

    const value = {
        isOpen,
        isHidden,
        openNavigation,
        closeNavigation,
        hideNavigation,
        showNavigation
    };

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationProvider;

export const useNavigation = () => {
    return useContext(NavigationContext);
};
