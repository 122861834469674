
import React from "react";
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import Image from '../../assets/background/F994.jpg';

const Block = styled(Box)(({theme}) => ({
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'repeat',
    backgroundSize: '70%',
    boxSizing: 'border-box',
    padding: theme.spacing(1)
}));

export default Block;
