import React from "react";
import Typography from "@mui/material/Typography";

const HeaderThreeImage = ({title}) => {
    return <Typography
        variant={"h3"}
        component={"h2"}
        sx={{
            display: {xs: 'block', md: 'inline-block'},
            marginBottom: 2,
            fontSize: {xs: '1.25rem', sm: '1.75rem'},
            textAlign: 'left',
            fontFamily: '"Darker Grotesque"'
        }}
    >{title}</Typography>

}

export default HeaderThreeImage;
